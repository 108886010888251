<template>
    <CRow>

        <CCol sm="3">
            <CModal
                    title="Choose which fields in table should be presented"
                    :show.sync="fieldChooseModal"
                    @update:show="closeModal"
                    closeOnBackdrop
                    size="xl"
            >
                <template v-for="(name, key) in checkboxNames">
                    <CRow form class="form-group" :key="name">
                        <CCol tag="label" sm="3" class="col-form-label">
                            {{name}}
                        </CCol>

                        <CCol sm="2" :class="key % 2 === 1 ? 'form-inline' : ''">
                            <CInputCheckbox
                                    id="apTableFields"
                                    v-for="(option, optionIndex) in options"
                                    :key="key + option"
                                    :label="option"
                                    :value="option"
                                    :custom="key > 1"
                                    :name="`Option 1${key}`"
                                    :inline="key % 2 === 1"
                            />
                        </CCol>
                        <br>

                    </CRow>
                </template>
            </CModal>

            <CModal
                    title="Update table entry"
                    :show.sync="updateModal"
                    @update:show="closeUpdateModal"
                    closeOnBackdrop
                    size="xl"
            >
                <CCard>
                    <CCardHeader>
                        Update modal
                        <CButton style="margin-left: 30px" color="danger" @click="deleteItem()"> Delete this entry.
                        </CButton>
                    </CCardHeader>
                    <CCardBody>
                        <CInput v-for="(value, field ) in selected"
                                :label="field"
                                :value="value"
                                :alt="field"
                                name="modfields"
                                horizontal
                        />
                    </CCardBody>

                </CCard>
            </CModal>

            <CModal
                    title="Create new entry"
                    :show.sync="createModal"
                    @update:show="closeCreateModal"
                    closeOnBackdrop
                    size="xl"
            >
                <CCard>
                    <CCardHeader>
                        Create new entry
                    </CCardHeader>
                    <CCardBody>
                        <CInput v-for="field in schema"
                                :label="field"
                                :alt="field"
                                name="addfields"
                                horizontal
                        />
                    </CCardBody>

                </CCard>
            </CModal>

            <CCard>
                <CCardHeader>
                    <strong> DB Query</strong> <small></small>

                    <div class="card-header-actions"></div>
                </CCardHeader>
                <CCardHeader>
                    <CTextarea v-model="search_criteria"
                               placeholder="select * from ...."
                               @input="lower()">
                        <template #prepend>
                            <CButton @click="executeQuery()" size="sm" color="primary">
                                <CIcon name="cil-magnifying-glass"/>
                                Search
                            </CButton>
                        </template>
                    </CTextarea>
                </CCardHeader>
            </CCard>
        </CCol>
        <CCol sm="8">
            <CCard>
                <CCardHeader>
                    Query result for table <span style="font-size: 20px; margin-left: 10px"
                                                 v-if="items"> {{table_name}} </span>
                    <CButton style="margin-left: 30px" color="primary" @click="fieldChooseModal = true"> Choose table
                        fields
                    </CButton>
                    <CButton style="margin-left: 30px" color="success" @click="createModal = true"> Create new entry
                    </CButton>
                </CCardHeader>
                <CCardBody>
                    <CDataTable
                            hover
                            striped
                            clickableRows
                            sorter
                            @row-clicked="rowClicked"
                            columnFilter
                            itemsPerPageSelect
                            responsive
                            :items="items"
                            :fields="fields"
                            :items-per-page="50"
                            :pagination="{ doubleArrows: true, align: 'center'}"

                    >
                    </CDataTable>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>


</template>

<script>
    // eslint-disable-next-line no-unused-vars
    import axios from 'axios';

    export default {
        name: 'GenericTablees',
        data() {
            return {

                items: {},
                fields: [],
                updateModal: false,
                search_criteria: "",
                fieldChooseModal: false,
                createModal: false,
                options: [],
                joined_table: false,
                selected: null,
                schema: [],
                table_name: "",
                checkboxNames: ['Choose fields']
            }
        },
        methods: {
            rowClicked(item) {
                if (this.joined_table) {
                    this.$toast.error("Cannot edit joined tables.")
                    return;
                }
                this.selected = item;
                this.updateModal = true;
            },

            deleteItem() {
                var fields = document.getElementsByName('modfields');
                var query = `delete from aerwave_database.${this.table_name} WHERE ${fields[0].alt}=${fields[0]._value}`

                axios.get(this.$baseURL + '/generic/executesql?sql_string=' + query,
                    {
                        headers: {Authorization: "Bearer " + localStorage.getItem("jwt")}
                    }
                ).then(
                    (res => {
                        console.log(res);
                        if (res.data === null) {
                            this.executeQuery()
                            this.$toast.success(`Deleted item ${fields[0]._value}`)
                            this.updateModal = false;
                        }
                    })
                ).catch((error) =>
                    this.$toast.error(error.response.data.detail))
            },
            closeUpdateModal(status, evt, accept) {
                if (accept) {
                    var fields = document.getElementsByName('modfields');
                    var attr_list = [];

                    // UPDATE aerwave_database.city SET cit_name='Test2', cit_zip='1', state_id='16' WHERE cit_id=3;

                    var where_str = "WHERE " + fields[0].alt + "=" + fields[0]._value;

                    for (var i = 1; i < fields.length; i++) {
                        attr_list.push(fields[i].alt + '=' + '"' + fields[i]._value + '"');
                    }
                    var items = attr_list.join(",")

                    var update_query = `update aerwave_database.${this.table_name} set ${items} ${where_str}`;

                    axios.get(this.$baseURL + '/generic/executesql?sql_string=' + update_query,
                        {
                            headers: {Authorization: "Bearer " + localStorage.getItem("jwt")}
                        }
                    ).then(
                        (res => {
                            console.log(res);
                            if (res.data === null) {
                                this.executeQuery()
                                this.$toast.success(`Updated item ${fields[0]._value}`)
                                this.updateModal = false;
                            }
                        })
                    ).catch((error) =>
                        this.$toast.error(error.response.data.detail))
                }

            },
            closeCreateModal(status, evt, accept) {
                if (accept) {
                    var fields = document.getElementsByName('addfields');
                    var col_list = [];
                    for (var i = 1; i < fields.length; i++) {
                        col_list.push(fields[i].alt);
                        val_list.push('"' + fields[i]._value + '"')
                    }
                    var items_cols = col_list.join(",")
                    var val_list = val_list.join(",")

                    var insert_query = `insert into aerwave_database.${this.table_name} (${items_cols}) VALUES (${val_list}) `;

                    axios.get(this.$baseURL + '/generic/executesql?sql_string=' + insert_query,
                        {
                            headers: {Authorization: "Bearer " + localStorage.getItem("jwt")}
                        }
                    ).then(
                        (res => {
                            console.log(res);
                            if (res.data === null) {
                                this.executeQuery()
                                this.$toast.success(`Added item ${fields[0]._value}`)
                                this.updateModal = false;
                            }
                        })
                    ).catch((error) =>
                        this.$toast.error(error.response.data.detail))
                }

            },
            closeModal(status, evt, accept) {
                if (accept) {
                    this.fields = [];
                    var checkboxes = document.querySelectorAll('input[id=apTableFields]:checked')

                    if (checkboxes.length === 0) {
                        this.fieldChooseModal = true;
                        this.$toast.error("Please select at least one field!")
                    }
                    for (var i = 0; i < checkboxes.length; i++) {
                        this.fields.push({
                            'key': checkboxes[i].value
                        })
                    }
                }
            },
            lower() {
                this.search_criteria = this.search_criteria.toLowerCase();
            },
            executeQuery() {


                var splited_query = this.search_criteria.split(' ');
                this.table_name = "";
                this.joined_table = false;
                if (splited_query.includes('join')) {
                    this.joined_table = true;
                }

                for (var i = 0; i < splited_query.length + 1; i++) {
                    if (splited_query[i] == 'from') {
                        this.table_name = splited_query[i + 1];
                        break;
                    }
                }

                console.log(this.table_name)


                axios.get(this.$baseURL + '/generic/executesql?sql_string=' + this.search_criteria,
                    {
                        headers: {Authorization: "Bearer " + localStorage.getItem("jwt")}
                    }).then(
                    (res => {

                        this.items = res.data;
                        this.fields = Object.keys(res.data[0]);
                        this.schema = this.fields;
                        this.schema.shift();
                        this.options = this.fields;


                    })
                ).catch((error) =>
                    this.$toast.error(error.response.data.detail))
            }


        },
        mounted() {
            this.validateSession();
        }
    }
</script>
